/* c__ for color */

$c__white: #fff;
$c__text: #000;
$c__red: #F40040;
$c__grey: #7C7C7C;
$mobile: 600px;

@-webkit-keyframes fadeIn{
    0%{opacity:0}to{opacity:1}
  }
  @keyframes fadeIn{
    0%{opacity:0}to{opacity:1}
  }
  .animate__fadeIn{
    -webkit-animation-name:fadeIn;
    animation-name:fadeIn;
  }
