@import "/src/shared.scss";

.contact {
    padding: 70px 14vw 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 560px;
    width: 100vw;
    &__info {
        width: 45%;
        margin-right: 16px;
        margin-top: 70px;
    }
    &__title{
        text-transform: uppercase;
        font-weight: 800;
        font-size: 36px;
        line-height: 36px;
        margin-bottom: 40px;
    }
    &__content {
        display: flex;
    }
    &__main {
        margin-right: 50px;
        display: flex;
        flex-direction: column;
    }
    &__item {
        margin-bottom: 30px;
    }
    &__name {
        font-weight: 600;
        font-size: 24px;
        line-height: 14px;
        color: $c__text;
        margin-bottom: 20px;
    }
    &__description {
        font-size: 18px;
        line-height: 14px;
        color: rgba(0, 0, 0, 0.5);
        &:hover {
            color: rgba(0, 0, 0, 0.5);
            text-decoration: underline;
        }
    }
    &__hours {
        display: flex;
        flex-direction: column;
        &-name {
            font-weight: 600;
            font-size: 24px;
            line-height: 22px;
            margin-bottom: 20px;
        }
    }
    &__time {
        font-weight: normal;
        font-size: 18px;
        line-height: 36px;
        color: rgba(0, 0, 0, 0.5);
    }
    &__form {
        width: 55%;
        padding: 40px 110px;
        border-radius: 5px;
        background: url('../../assets/contact_bg.png') center center / cover;
    }
   
}

@media (max-width: $mobile) {
    .contact {
        padding: 30px 0px;
        display: flex;
        align-items: flex-start; 
        justify-content: flex-start;
        height: auto; 
        width: auto; 
        flex-direction: column;
        &__content {
            flex-direction: column;
        }
        &__info {
            padding: 0 30px;
            margin: 0;
            width: 100%;
        }
        &__title {
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            margin-bottom: 30px;
        }
        &__name {
            font-weight: 600;
            margin-bottom: 20px;
            font-size: 24px;
            line-height: 14px;
        }
        &__description {
            font-size: 18px;
            line-height: 14px;
        }
        &__hours-name {
            font-size: 24px;
            line-height: normal;
        }
        &__time {
            margin-bottom: 50px;
        }
        &__form {
            width: 100%;
            padding: 30px 15px;
        }
    }
}