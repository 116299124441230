@import "./shared.scss";

// Main font
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

* {
  box-sizing: border-box;
}

body {
  color: $c__text;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
}

#root {
  background: $c__white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  overflow: hidden;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: $c__text;
  transition: color 250ms;
}

.d-none {
  display : none !important;
}

.home .ant-carousel {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.home .slick-slider {
  height: 100%;
}

.home .slick-list {
  height: 100%;
}

.home .slick-track {
  height: 100%;
}

.home .slick-slide {
  height: 100%;
  background: "center center / cover no-repeat url('../../assets/bg.png')";
  & div {
    height: 100%;
  }
}

.ant-carousel .slick-dots li button {
  width: 22px !important;
  height: 22px !important;
  opacity: 1 !important;
  border: 1px solid $c__white !important;
  border-radius: 100% !important;
  background: transparent !important;
}

.ant-carousel .slick-dots li.slick-active button {
  background: $c__red !important;
}

.ant-carousel .slick-dots li {
  width: 22px !important;
  height: 22px !important;
  margin-right: 0px !important; 
  margin-left: 0px !important;
  margin: 0 13px !important; 
}

.ant-carousel .slick-dots-bottom {
  height: 24px;
}

.ant-carousel .slick-dots {
  z-index: 1 !important;
}
.button {
  cursor: pointer;
  border: none;
  position: relative;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  padding: 13px 70px;
  background: $c__red;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: $c__white;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
      color: $c__white;
      box-shadow: 0px 2px 7px rgba(244, 0, 64, 0.5);
  }
}

.container {
  width: 80%;
  margin: 0 auto;
}

.ant-btn-primary {
  background: $c__red !important;
  border-color: $c__red !important;
}

