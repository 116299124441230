@import "/src/shared.scss";
.fixed-header {
    position: sticky;
    position: -webkit-sticky;
    margin-bottom: -80px;
    z-index: 2;
    top:0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: rgba(0, 0, 0, 0.55);
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 80px;
    transition: all 0.3s ease-in-out;
    &__nav {
        width: 33.3%;
        display: none;
        &_desktop {
            display: block;
        }
        &_second {
            display: flex;
            justify-content: flex-end;
        }
    }
    &__list {
        display: flex;
        padding: 0;
        margin: 0;
    }
    &__item {
        cursor: pointer;
        margin: 0;
        list-style: none;
        margin: 0 15px;
        &:hover {
            &::after {
                width: 100%;
            }
        }
        &::after {
            display: block;
            content: '';
            width: 0%;
            height: 1px;
            margin-top: 1px;
            transition: all 0.2s ease-in-out;
            background: $c__white;
        }
    }
    &__link {
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: $c__white;
        &:hover {
            color: $c__white;
        }
    }
    &__logo {
        
    }
    &__burger {
        display: none;
    }
}

@media (max-width: $mobile) {
    .fixed-header {
        display: flex;
        padding: 0 15px;
        margin-bottom: -40px;
        height: 40px;
        justify-content: space-between;
        &__nav {
            &_desktop {
                display: none;
            }
            &_second {
                display: none;
            }
        }
        &__logo {
            width: 135px;
        }
        &__burger {
            display: block;
            width: 16px;
            height: 12px;
        }
    }
}