@import "/src/shared.scss";

.questions {
    background: url('../../assets/faq_bg.png') center center / cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 70px 14vw;
    &__title {
        text-transform: uppercase;
        font-weight: 800;
        font-size: 36px;
        line-height: 36px;
        color: $c__white;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        margin-bottom: 30px;
        &_mobile {
            display: none;
        }
    }
    &__item {
        padding: 21px 34px 44px;
        background: $c__white;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #444444;
        border-radius: 5px;
    }

    .ant-collapse-header {
        padding: 22px 30px !important;
        font-weight: 800;
        font-size: 16px;
        line-height: 19px;
    }
    .ant-collapse-content > .ant-collapse-content-box {
        border-radius: 5px;
        background: transparent;
        padding: 0 !important;
    }
    .ant-collapse-content {
        background: none !important;
        bottom: none !important;
    }
    .ant-collapse-item {
        position: relative;
        margin-bottom: 30px;
        width: 100%;
        border-radius: 5px;
        margin: 0 15px 30px 0;
        &-active {
            box-shadow : 0px 2px 7px rgba(0, 0, 0, 0.35);
            & .ant-collapse-header {
                border: 2px solid $c__red !important;
            }
            & .two {
                -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
                background: $c__red;
            }
            .questions__btn-right {
                background-image: url('../../assets/minus.svg') !important;
            }
        }
    }

    .ant-collapse > .ant-collapse-item {
        border-bottom: none !important;    
    }

    &__btn-right {
        cursor: pointer;
        background-image: url('../../assets/plus.svg');
        display: block;
        background-size: 19px 19px;
        height: 19px;
        width: 19px;
        margin-top: 3px;
        margin-right: -5px;
    }
    
    &__btn {
        margin-top: 20px;
    }
    .ant-collapse{
        margin: 0 15px;
        width: 100%;
        border : none;
        background: transparent;
    }
    &__collapse-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header{
        background: $c__white;
        border: 2px solid rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        border-radius: 5px;
    }

    .one, .two {
        position: absolute;
        border-radius: 3px;
        background: #fff;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 400ms all ease-out;
        transition: 400ms all ease-out;
      }
    
      .ant-collapse-arrow {
          display: none !important;
      }
      
      .one {
        top: 11px;
        left: 0px;
        right: 0px;
        height: 4px;
        background: $c__grey;
      }
      
      .two {
        top: 1px;
        left: 11px;
        bottom: 0px;
        width: 4px;
        background: $c__grey;
      }
      &__icon {
          position: absolute;
          right: 25px;
          top: 25px;
      }
      
      .icon {
        position: relative;
        width: 25px;
        height: 25px;
        
        &:hover {
          cursor: pointer;
          
          
        }
      }
}

@media (max-width: $mobile) {
    .questions {
        padding: 30px 15px 40px;
        &__title {
            margin-bottom: 20px;
            font-size: 24px;
            line-height: 30px;
            &_desktop {
                display: none;
            }
            &_mobile {
                display: block;
            }
        }
        &__collapse-part {
            &_desktop {
                display: none;
            }
        }
        & .ant-collapse {
            margin: 0;
        }
    }
}