@import "/src/shared.scss";

.gallery {
    background: linear-gradient(97.35deg, #000000 1.83%, #F40040 109.18%);
    padding: 70px 12vw 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    &__title{
        color: $c__white;
        text-transform: uppercase;
        text-align: center;
        font-weight: 800;
        font-size: 36px;
        line-height: 36px;
        margin-bottom: 30px;
    }
    &__text {
        color: $c__white;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 30px;
        width: 90%;
    }
    &__slide-inner {
        width: 190px;
        height: 150px;
        position: relative;
        transition: all 0.1s ease-in-out;
    }
    &__slide-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 400px;
    }
    &__slide-plus {
        display: none !important;
    }
    .slick-center &__slide-plus {
        cursor: pointer;
        position: absolute;
        right: 12px;
        bottom: 20px;
        display: block !important;
    }
    &__carousel {
        height: 400px;
        width: 100vw;
    }
    .slick-center .gallery__slide-inner{
       width: 100%;
       height: 350px; 
    }
    .slick-center .gallery__slide-wrap{
        width: 140% !important;
        margin-left: -20%;
    }
    .ant-carousel .slick-dots-bottom {
        bottom: -40px !important;
    }
    &__fullPhoto {
        height: 100%;
        width: 100%;
    }
    
}
.ant-modal-footer {
    display: none !important;
}
.ant-modal-title {
    display: none !important;
}
.ant-modal-header {
    border-bottom: none !important;
}

@media (max-width: $mobile) {
    .gallery {
        padding: 30px 15px 76px;
        &__title {
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 10px;
            margin-top: 0;
        }
        &__text {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 20px;
        }

        &__slide-inner {
            width: 100% !important;
            height: 400px !important; 
            transition: all 0.1s ease-in-out;
        }
        &__slide-wrap{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 400px !important;
            width: 100% !important;
        }
        &__carousel {
            height: 430px !important;
            width: 95vw !important;
        }
        .slick-center .gallery__slide-inner{
           width: 100% !important;
           height: 400px !important; 
        }
        .slick-center .gallery__slide-wrap{
            width: 100% !important;
            margin-left: 0% !important;
        }
        .ant-carousel .slick-dots-bottom {
            bottom: -40px !important;
        }
    }
}