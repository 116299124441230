@import "/src/shared.scss";

.about {
    background: url('../../assets/bg2.png') center center / cover ;
    padding: 70px 20vw 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__title{
        color: $c__white;
        text-align: center;
        font-weight: 800;
        font-size: 36px;
        line-height: 36px;
        margin-bottom: 30px;
        text-transform: uppercase;
    }
    &__text {
        color: $c__white;
        margin-bottom: 20px;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
    }
    &__btn {
        margin-top: 30px;
    }
    &__more {
        display: none;
    }
}

@media (max-width: $mobile) {
    .about {
        background: url('../../assets/about_mobile_bg.png') center center / cover ;
        padding: 40px 15px 150px;
        &__title {
            font-size: 24px;
            margin-bottom: 20px;
        }
        &__text {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 0;
            &:first-child {
                
            }
            &_onlyDesk {
                display: none;
            }
            &_fade {
                animation-name: fadeIn;
                animation-duration: .4s;
            }
        }
        &__more {
            width: 100%;
            margin-top: 10px;
            display: block;
            cursor: pointer;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            text-align: left;
            color: $c__red;
        }
    }
}