@import "/src/shared.scss";
.burger {
    &__nav {
        margin: 0;
    }
    &__list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        flex-direction: column;
        padding: 0;
    }
    &__item {
        cursor: pointer;
        margin: 0;
        list-style: none;
        margin: 20px 0;
        &:hover {
            &::after {
                width: 100%;
            }
        }
        &::after {
            display: block;
            content: '';
            width: 0%;
            height: 1px;
            margin-top: 1px;
            transition: all 0.2s ease-in-out;
            background: $c__text;
        }
    }
    &__link {
        font-weight: normal;
        font-size: 22px;
        line-height: 22px;
        color: $c__text;
        &:hover {
            color: $c__text;
        }
    }
}