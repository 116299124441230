@import "/src/shared.scss";
.footer {
    background: #000000;
    padding: 30px 6vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__logo{
        // margin-right: 46px;
        width: 200px;
    }
    &__nav {
        margin: 0;
        margin-right: 30px;
       
    }
    &__list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
    }
    &__item {
        cursor: pointer;
        margin: 0;
        list-style: none;
        margin: 0 15px;
        &:hover {
            &::after {
                width: 100%;
            }
        }
        &::after {
            display: block;
            content: '';
            width: 0%;
            height: 1px;
            margin-top: 1px;
            transition: all 0.2s ease-in-out;
            background: $c__white;
        }
    }
    &__link {
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: $c__white;
        &:hover {
            color: $c__white;
        }
    }
    &__contacts {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__contact {
        &:first-child {
            margin-right: 30px;
        }
        color: $c__white;
        &:hover {
            color: $c__white;
        }
    }
    &__text {
        color: $c__white;
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
    }
    &__icon {
        margin-right: 10px;
    }
}

@media (max-width: $mobile) {
    .footer {
        flex-direction: column;
        &__list {
            flex-direction: column;
        }
        &__logo {
            margin-bottom: 10px;
        }
        &__item {
            margin: 10px 0;
        }
        &__contacts {
            flex-direction: column;
            margin-top: 10px;
            
        }
        &__contact {
            margin-bottom: 20px;
            &:first-child {
                margin-right: 0;
            }
        }
    }
}