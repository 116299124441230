@import "/src/shared.scss";
.pre-header {
    width: 100%;
    background: #F40040;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    &__item {
        display: flex;
        align-items: center;
        &:first-child{
            margin-right: 50px;
        }
    }
    &__text {
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
        color: $c__white;
        margin-left: 10px;
    }
}

@media (max-width: $mobile) {
    .pre-header {
        width: 100%;
        background: #F40040;
        height: 40px;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__item {
            &:first-child{
                margin-right: 0px;
            }
        }
        &__text {
            margin-left: 5px;
            font-weight: 800;
            font-size: 14px;
            line-height: 17px;
        }
    }
}