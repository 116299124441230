@import "/src/shared.scss";

.benefits {
    padding: 70px 12vw 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    &__title{
        text-transform: uppercase;
        text-align: center;
        font-weight: 800;
        font-size: 36px;
        line-height: 36px;
    }
    &__text {
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 50px;
        width: 90%;
    }
    &__row {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &_2 {
            margin-top: 30px;
        }
    }
    &__item {
        background: $c__white;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        padding: 33px 63px 58px 63px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 10px;
    }
    &__img {
        width: 64px;
        height: 64px;
        margin-bottom: 15px;
    }
    &__name {
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 0;
    }
    &__btn {
        margin-top: 50px;
    }
}

@media (max-width: $mobile) {
    .benefits {
        padding: 50px 15px;
        &__title {
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 10px;
        }
        &__text {
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            margin-bottom: 0;
        }
        &__item {
            margin-top: 20px;
            margin-bottom: 0;
            padding: 20px 35px 40px ;
        }
        &__btn {
            margin-top: 30px;
        }
        &__row {
            flex-wrap: wrap;
        }
        &__img {
            width: 40px;
            height: 40px;
            margin-bottom: 10px;
        }
        &__name {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 0;
        }
    }
}