@import "/src/shared.scss";

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__title {
        font-weight: 900;
        font-size: 24px;
        line-height: 24px;
        color: $c__white;
        margin-bottom: 20px;
        text-align: center;
    }
    &__inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__field {
        width: 100%;
        border: 0.5px solid $c__red;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        background: $c__white;
        margin-bottom: 20px;
        padding: 18px 30px;
    }
    &__btn {
        margin-top: 10px;
        padding: 13px 95px;
    }
    &__textarea {
        height: 140px;
    }
}

@media (max-width: $mobile) {
    .form {
        
    }
}