@import "/src/shared.scss";

.review {
    padding: 70px 12vw 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden !important;
    height: 560px;
    width: 100vw;
    &__title{
        text-transform: uppercase;
        text-align: center;
        font-weight: 800;
        font-size: 36px;
        line-height: 36px;
        margin-bottom: 28px;
    }
    &__text {
        color: $c__white;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 30px;
        width: 90%;
    }
    &__slide-inner {
        width: 100%;
        height: 400px;
        transition: all 0.1s ease-in-out;
    }
    &__slide-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 400px;
    }
    &__carousel {
        height: 400px;
        width: 100vw;
    }
    &__item {
        width: 95%;
        display: flex;
        flex-direction: column;
    }
    &__card {
        background: #FAFAFA;
        border-radius: 5px;
        padding: 24px 16px 23px 28px;
        position: relative;
        &-top {
            display: flex;
            flex-direction: column;
            margin- : 10px;
        }
    }
    &__quotes {
        margin-right: 8px;
        margin-top: -10px;
    }
    &__images {
        display: flex;
    }
    &__extra-text {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $c__grey;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    &__content {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $c__grey;
    }
    &__link {
        font-size: 14px;
        line-height: 20px;
        text-decoration-line: underline;
        color: #3346D3;
        &:hover {
            text-decoration: none;
            color: #3346D3;
        }
    }
    &__icon {
        width: 24px;
        height: 24px;
        position: absolute;
        bottom: 16px;
        right: 16px;
    }
    &__person {
        margin-top: 40px;
        display: flex;
        align-items: center;
    }
    &__face {
        width: 40px;
        height: 40px;
        margin-right: 24px;
    }
    &__credentials {
        display: flex;
        flex-direction: column;
    }
    &__name {
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #3346D3;
        margin-bottom: 12px;
    }
    &__time {
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        color: $c__grey;
        margin-bottom: 0;
    }
    .ant-carousel {
        width: 75vw;
    }

    .slick-arrow {
        color: $c__red !important;
        top: unset !important;
        bottom: 25px !important;
        left: 47.5% !important;
        z-index: 50 !important;
    }
    &__arrow {
        margin: 0 15px;
        margin-top: -95px;
        &_next {
            transform: rotate(180deg);
        }
    }
    .ant-carousel .slick-next {
        left: 50.5% !important;
    }
}

@media (max-width: $mobile) {
    .review {
        padding: 40px 20px 50px;
        &__title {
            margin-bottom: 20px;
            font-size: 24px;
            line-height: 30px;
        }
        &__extra-text {
            display: none;
        }
        &__text {
            font-size: 14px;
            line-height: 20px;
        }
        &__person {
            margin-top: 25px;
        }
        & .slick-arrow {
            bottom: 6px !important;
        }
        &__arrow {
            margin-top: -58px;
        }
        & .ant-carousel .slick-next {
            left: 53.5% !important;
        }
        & .ant-carousel .slick-prev {
            left: 39.5% !important;
        }
    }
}