@import "/src/shared.scss";
.home {
    position: relative;
    padding-top: 243px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__title {
        position: relative;
        font-weight: 800;
        font-size: 64px;
        line-height: 64px;
        color: $c__white;
        text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        margin-bottom: 50px;
        text-align: center;
        text-transform: uppercase;
    }
    &__btn {
        margin-bottom: 151px;
    }
    &__slide-inner {
        height: 100%;
        width: 100% !important;
        background: center center / cover no-repeat url('../../assets/bg.png');
        
    }
}

@media (max-width: $mobile) {
    .home {
        padding-top: 60px;
        padding-bottom: 65px;
        &__title {
            font-size: 36px;
            line-height: 40px;
            margin-bottom:194px;
        }
        &__btn {
            margin-bottom: 00px;
        }
    }
}